import React, {useEffect, useState} from 'react'
import {Redirect, Route, useHistory} from 'react-router-dom'
import Loading from '../loading/Loading'
import storage from '../../storages/UserStorage'
import {addUser} from '../../redux/actions/UserAction'
import {connect} from 'react-redux'

const PrivateRoute = ({component: Component, dispatch, ...rest}) => {

	const history = useHistory()

	let [loading, setLoading] = useState(true)
	let [user, setUser] = useState(null)

	useEffect(() => {
		const renderRoutes = async () => {
			const authenticated = await storage.getItemJson()

			if (authenticated && authenticated['access_token']) {
				if(storage.getNickname(authenticated.user['username']) !== '')
					authenticated.user['nickname'] = storage.getNickname(authenticated.user['username'])

				dispatch(addUser(authenticated))
				setUser(authenticated)
				setLoading(false)
			} else 
				await history.push('/')
		}

		renderRoutes().then()
	}, [dispatch, history])

	return (
		loading ?
			<div className='container pt-3'>
				<Loading/>
			</div>
			:
			<Route
				{...rest}
				render={props =>
					user ? (
						<React.Fragment>
							<Component {...props} />
						</React.Fragment>
					) : (
						<Redirect to={{pathname: '/'}}/>
					)
				}
			/>
	)
}

const mapStateToProps = (state) => ({
	user: state.userStore.user,
})

export default connect(mapStateToProps)(PrivateRoute)
