import React from 'react'
import _ from 'lodash'
import moment from 'moment'

export const HSPW = underline => {
  return (
    <span className={`fa-main-bold ${underline ? 'fa-underline' : ''}`}>
      HSPW
    </span>
  )
}
export const bold = texto => {
  return <span className={'fa-bold'}>{texto}</span>
}
export const underline = (texto, bold = false) => {
  return <span className={`fa-underline ${bold ? 'bold' : ''}`}>{texto}</span>
}
export const mainBold = texto => {
  return <span className={'fa-main-bold'}>{texto}</span>
}

export const traduzPercentToScore = (percentual, escala_severidade) => {
  if (!percentual) return '0'

  let tmp = parseFloat(percentual)
  return isNaN(tmp)
    ? '0'
    : escala_severidade === 'CRESCENTE'
    ? tmp < 25
      ? '1'
      : tmp < 50
      ? '2'
      : tmp < 75
      ? '3'
      : '4'
    : tmp < 25
    ? '4'
    : tmp < 50
    ? '3'
    : tmp < 75
    ? '1'
    : '1'
}

export const traduzScoreToPercent = (score, escala_severidade) => {
  let tmp = parseInt(score)
  return isNaN(tmp)
    ? '0'
    : escala_severidade === 'CRESCENTE'
    ? tmp === 1
      ? '0'
      : tmp === 2
      ? '50'
      : tmp === 3
      ? '75'
      : '100'
    : tmp === 1
    ? '100'
    : tmp === 2
    ? '75'
    : tmp === 3
    ? '50'
    : '0'
}

export const formatPhoneNumber = str => {
  const cleaned = ('' + str).replace(/\D/g, '').substring(0, 11)
  const match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }

  return str
}

export const sortItemOptionsAnswer = item => {
  function isOpcaoField(field = '') {
    return field.indexOf('opcao') > -1
  }

  function formatArray(item) {
    let arr = []

    for (const key in item) {
      if (isOpcaoField(key)) {
        let campoOrdem = 'ordem_' + key.replace(/\D/g, '')

        let obj = {
          opcao: key.replace(/\D/g, ''),
          valor_ordem: item[campoOrdem]
        }

        arr = [...arr, obj]
      }
    }

    return arr
  }

  const formattedArr = formatArray(item)

  const sortedArr = _.orderBy(formattedArr, 'valor_ordem', 'asc')

  return sortedArr.map(item => item.opcao)
}

export const dateTimeFormat = date => {
  return moment(date).format('DD/MM/YYYY HH:mm:ss')
}

export const dateFormat = date => {
  return moment(date).format('DD/MM/YYYY')
}

export function sanitizeToSort(str) {
  return str
    ? typeof str === 'number'
      ? parseInt(str)
      : str
          .toString()
          .normalize('NFD') // REMOVE ACCENTED CHARS
          .replace(/[\u0300-\u036f]/g, '') // REMOVE DIACRITICS
          .toLowerCase()
    : '' //str
}

export function sortByProperty(arr, property, sortASC) {
  arr.forEach(item => (item.tempProp = sanitizeToSort(item[property])))
  arr.sort((a, b) =>
    sortASC
      ? a.tempProp > b.tempProp
        ? 1
        : a.tempProp < b.tempProp
        ? -1
        : 0
      : a.tempProp > b.tempProp
      ? -1
      : a.tempProp < b.tempProp
      ? 1
      : 0
  )
  arr.forEach(item => delete item.tempProp)
  return arr
}

export function getUrlBase(api = false) {
  const AMBIENTE = process.env.REACT_APP_AMBIENTE || 'local'
  let url = ''
  switch (AMBIENTE) {
    case 'prod':
      url = `https://${api ? 'api' : 'app'}.hspw.com${api ? '' : '/'}`
      break
    case 'hml':
      url = `https://${api ? 'api-hml' : 'hml'}.hspw.com${api ? '' : '/'}`
      break
    case 'demo':
      url = `https://${api ? 'api-demo' : 'demo'}.hspw.com${api ? '' : '/'}`
      break
    case 'dev':
      url = `http://192.168.0.1021:${api ? '3000' : '3001/'}`
      break
    default:
      // Nenhum dos ambientes previstos: desenvolvimento local.
      url = `http://192.168.0.102:${api ? '3000' : '3001/'}`
  }

  return url
}

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export function removerAcentos(s) {
  return s !== '' ? s.normalize('NFD').replace(/[\u0300-\u036f]/g, '') : s
}
